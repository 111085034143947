import { AxiosError } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import eventBus from '@/common/bus'

export default class NotificationsController {
  public static async AddUserToNotificationsForJob(jobId: string): Promise<boolean> {
    try {
      const { status } = await CallCentreApi.post(`notifications/job/${jobId}`)
      return status >= 200 && status < 300
    } catch (err) {
      eventBus.$emit('errorHandler', err as AxiosError, false)
      return false
    }
  }

  public static async RemoveUserFromNotificationsForJob(jobId: string): Promise<boolean> {
    try {
      const { status } = await CallCentreApi.delete(`notifications/job/${jobId}`)
      return status >= 200 && status < 300
    } catch (err) {
      eventBus.$emit('errorHandler', err as AxiosError, false)
      return false
    }
  }
}
