<template>
  <div class="jobView page-container" :class="getIsAutoDeploymentRunning ? 'auto-deployment' : ''">
    <div class="overlay-msg" :class="getIsAutoDeploymentRunning ? 'show' : 'hide'"></div>
    <div class="page-content">
      <div
        class="page-content-side jobview-card-sidebar"
        :class="job && job.additionalCostsAuthorised ? 'additional-payment' : ''"
      >
        <v-progress-circular
          v-show="job === undefined"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
          :style="getLoaderStyle(70)"
        ></v-progress-circular>
        <TimeLine
          v-if="job"
          ref="timeLine"
          :job-id="jobId"
          :record-id="recordId"
          :request-type="requestType"
          :record-type="recordType"
          @cardWithDetailActivated="cardWithDetailActivated"
        ></TimeLine>
      </div>
      <div class="page-content-main">
        <v-system-bar v-if="!liveSync" window color="secondary">
          <v-toolbar-title style="color: white">
            Live Sync Status:
            <span v-if="!liveSync && getSignalRConnectionStatus == 'connected'">
              disconnected,
              <a style="color: white; text-decoration: underline" @click="reconnectToGroup()">click to re-connect</a>
            </span>
            <span v-else>{{ getSignalRConnectionStatus }}</span>
          </v-toolbar-title>
        </v-system-bar>
        <TimeLinePreview
          v-if="previewModel"
          :model="previewModel"
          @refreshData="refreshData"
          @closePreview="clearSelection"
        ></TimeLinePreview>
      </div>
      <div v-bar="{ useScrollbarPseudo: true }" class="page-content-side map-content-sidebar pl-4">
        <div class="main-inner-content">
          <div>
            <v-layout wrap>
              <v-flex v-if="job" class="map">
                <Map
                  :map-id="new Date().getTime().toString()"
                  :source-latitude="job.latitude"
                  :source-longitude="job.longitude"
                  :source-address="job.getAddress(', ')"
                  :zoom-level="13"
                  :show-place-card="true"
                  :show-travel-mode="true"
                  :disable-default-u-i-control="true"
                  style="height: 380px"
                ></Map>
                <CustomerCard
                  v-if="job && !job.policyEnquiry"
                  :job-id="jobId"
                  @CustomerToPay="customerToPayClicked"
                  @complaintRaised="complaintClicked"
                ></CustomerCard>
              </v-flex>
              <v-flex
                v-if="job && !job.policyEnquiry && !job.helplinePolicy"
                xs12
                class="btn-card-filter text-wrap-chip"
              >
                <span class="chip-btn">
                  <span v-for="emergency in job.emergencies" :key="emergency.id">
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-chip
                          color="teal"
                          text-color="white"
                          :small="true"
                          class="emergency-chip"
                          :class="emergency.displayInTimeline ? 'active-car-flbtn' : ''"
                          v-on="on"
                          @click.native="emergencyChipClicked(emergency.id)"
                        >
                          <v-avatar :size="26">
                            <img
                              v-if="emergency.fileURL !== '' && emergency.fileURL !== null"
                              :src="emergency.fileURL"
                              alt=""
                            />
                            <img v-else src="/img/emergency.svg" alt="" />
                          </v-avatar>
                          {{ emergency.detailDescription }}
                        </v-chip>
                      </template>
                      <span>
                        {{ emergency.typeDescription ? emergency.typeDescription : emergency.detailDescription }}
                      </span>
                    </v-tooltip>
                  </span>
                </span>
                <v-tooltip
                  v-if="job.status !== 'Completed' && !job.cancellationReason && job.jobType === jobType"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn flat icon color="primary" @click.native.stop="openAddEmergency" v-on="on">
                      <v-icon dark color="primary">add</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Emergency</span>
                </v-tooltip>
              </v-flex>
              <v-flex v-if="job" xs12>
                <WeatherWidgetAndJobNote :job-id="jobId"></WeatherWidgetAndJobNote>
              </v-flex>
            </v-layout>
            <v-dialog v-model="showEmergencyDialog" max-width="650" content-class="v-dialog--scrollable" persistent>
              <v-card>
                <v-toolbar card dark color="primary">
                  <v-toolbar-title>New HE Emergency</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click.native="closeAddHEEmergencyDialog">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider />
                <v-card-text class="px-3 scroll-content-dialog">
                  <v-layout>
                    <AddHEEmergency
                      ref="addEmergency"
                      v-model="addEmergency"
                      :add-emergency-index="0"
                      :policy-url="policyUrl"
                    ></AddHEEmergency>
                  </v-layout>
                </v-card-text>
                <v-divider />
                <v-card-actions class="px-3">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" flat="flat" @click.native="closeAddHEEmergencyDialog">Close</v-btn>
                  <v-btn color="primary" class="mr-0" :disabled="isLoading" :loading="isLoading" @click="saveEmergency">
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- show new job confirmation while adding new emergency -->
            <v-dialog
              v-if="showNewJobConfirmationOnAddEmergency"
              v-model="showNewJobConfirmationOnAddEmergency"
              max-width="650"
              content-class="v-dialog--scrollable"
              persistent
            >
              <v-card>
                <v-toolbar card dark color="primary">
                  <v-toolbar-title>New Emergency Confirmation</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn v-if="!jobCreationInProcess" icon @click.native="showNewJobConfirmationOnAddEmergency = false">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider />
                <v-card-text class="px-3 scroll-content-dialog">
                  <v-layout wrap>
                    <span>
                      Are you sure that this Emergency is related to the same Peril as the first emergency? If you are,
                      then click yes.
                    </span>
                    <span class="pt-2">
                      If this additional emergency should actually be a new job, then click, new job.
                    </span>
                  </v-layout>
                </v-card-text>
                <v-divider />
                <v-card-actions class="px-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    flat="flat"
                    :loading="jobCreationInProcess && isLoading"
                    :disabled="jobCreationInProcess"
                    @click="saveClaim(true)"
                  >
                    New Job
                  </v-btn>
                  <v-btn color="primary" class="mr-0" :disabled="jobCreationInProcess" @click="AddNewEmergencyToJob">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
    <ClearEtaDialog :job-id="jobId" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Job from '@/models/Job'
import Emergency from '@/models/Emergency'
import JobController from '@/api/jobController'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import TimeLineCardModel from '@/models/TimeLineCardModel'

import TimeLine from '@/components/TimeLine.vue'
import TimeLinePreview from '@/components/TimeLinePreview.vue'
import Map from '@/components/Map.vue'
import CustomerCard from '@/components/CustomerCard.vue'
import WeatherWidgetAndJobNote from '@/components/WeatherWidgetAndJobNote.vue'
import AddHEEmergency from '@/components/AddHEEmergency.vue'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import AddEmergency from '@/models/requests/AddEmergency'
import Shared from '@/common/shared'
import DateTimePicker from '@/components/DateTimePicker.vue'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'
import ClearEtaDialog from '@/components/timeline/ClearEtaDialog.vue'

import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import RequestProcessedRecord from '@/signalr/jobModels/requestProcessedRecord'
import SessionController from '@/api/sessionController'
import NotificationsController from '@/api/notifications-controller'
import eventBus from '@/common/bus'
import { JobType } from '@/models/types'
import PolicyHolderController from '@/api/policyHolderController'
import { useTimelineStore } from '@/pinia/timeline'
import TimelineCardModel from '@/models/general/timeline'
import { ContractorAppointment, Delay } from '@/models/autoDeployment/auto-deployment'
import { useDeploymentStore } from '@/pinia/deployment'
import ShowSnackbar from '@/models/snackbar/show-snackbar'

// tslint:disable:no-console

@Component({
  components: {
    TimeLine,
    TimeLinePreview,
    Map,
    CustomerCard,
    WeatherWidgetAndJobNote,
    AddHEEmergency,
    ClearEtaDialog,
  },
  name: 'jobView',
})
export default class JobView extends Vue {
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('jobNotifications')
  private deploymentSignalRHub = new SignalRHubConnection('autoDeployment')

  @Prop()
  private jobId: string
  @Prop() private recordId: string
  @Prop() private requestType: string
  @Prop() private recordType: string
  private previewModel: TimeLineCardModel | null = null
  private showEmergencyDialog = false
  private addEmergency: AddEmergency | null = null
  private isLoading = false
  private claimModel: AddHEJobWithEmergencies = new AddHEJobWithEmergencies()
  private liveSync = false
  private policyUrl: string | null = ''
  private isAutoDeploymentRunning = false
  private jobType: JobType = 'HE'
  private showNewJobConfirmationOnAddEmergency = false
  private jobCreationInProcess = false
  private emergencyAddedFromAnswer: Emergency[] = []

  private deploymentSignalRHandlers = {
    contractorAppointmentUpdated: (deploymentId: string, contractorId: string, appointment: ContractorAppointment) =>
      this.updateContractorAppointment(deploymentId, contractorId, appointment),
    deploymentDelayUpdated: (deploymentId: string, delays: Delay[]) => this.updateDeploymentDelay(deploymentId, delays),
    deploymentStatusUpdated: (deploymentId: string, status: string) =>
      this.updateDeploymentStatus(deploymentId, status),
  }

  private created() {
    this.signalRHub.addHandler('timelineCardAdded', (payload: TimelineCardModel) => {
      if (this.jobId !== payload.jobId) return
      useTimelineStore().updateTimelineCard(payload)
    })
    this.signalRHub.addHandler(
      'jobRequestProcessed',
      (
        jobId: string,
        requestId: string,
        requestType: string,
        processedItems: RequestProcessedRecord[],
        success: boolean
      ) => {
        if (!success) {
          // TODO: on non success, warn user?
          // only if view is current job
          eventBus.$emit(
            'errorHandler',
            'SR: ERROR result for request: ' + requestType + ' (' + requestId + ') for jobId: ' + jobId
          )
          return
        }

        if (requestType === 'AddHEJobWithEmergencies') {
          // ignore as done by add directly
          return
        }

        // loop over processed items collection
        if (processedItems && processedItems.length > 0) {
          processedItems.forEach((item) => {
            if (item.type !== 'JobHeader') {
              // for items created or updated grab a fresh copy of the document
              // from the API (which in turn will update the vuex store)
              if (item.action === 'Created' || item.action === 'Updated') {
                JobController.GetJobDocument(jobId, item.id).then((res: any) => {
                  // if requestType is AddEmergency and emergency is logged by System, add popup to show emergency info
                  if (
                    requestType === 'AddEmergency' &&
                    res &&
                    this.$route &&
                    this.$route.params.jobId &&
                    res.jobId === this.$route.params.jobId &&
                    res.loggedByUserName &&
                    res.loggedByUserName.toLowerCase() === 'system'
                  ) {
                    // added popup to App.vue to prevent auto hide popup on signalr change - timeline rebind
                    this.emergencyAddedFromAnswer.push(res)
                    eventBus.$emit('addEmergencyBasedOnAnswer', this.emergencyAddedFromAnswer)
                  }
                  if (this.job && (item.type === 'SMS' || item.type === 'Email')) {
                    // refresh if a new record was added to the existing list in the store
                    this.job.triggerTimeLineRebuild()
                  }
                })
              } else if (item.action === 'Removed') {
                storeMutations.removeJobDocument(jobId, item.id, item.type)
              }
            }
          })
        }
      }
    )

    this.signalRHub.addHandler('jobGroupAdded', (jobId: string) => {
      if (jobId === this.jobId) {
        this.liveSync = true
      }
    })

    this.signalRHub.addHandler('jobGroupRemoved', (jobId: string) => {
      if (jobId === this.jobId) {
        this.liveSync = false
      }
    })

    // handler to remove existing policy question when policy schedule changes
    this.signalRHub.addHandler('removePolicyWideQuestion', (jobId: string, questionId: string) => {
      if (jobId && questionId) {
        storeMutations.removePolicyWideQA(jobId, questionId)
      }
    })

    this.signalRHub.connect()
    this.addSignalRHandlers(this.deploymentSignalRHub, this.deploymentSignalRHandlers)
  }

  private addSignalRHandlers<T extends Record<string, (...args: any[]) => void>>(
    signalRHub: SignalRHubConnection,
    handlers: T
  ) {
    for (const [key, value] of Object.entries(handlers)) {
      signalRHub.addHandler(key, value)
    }
    signalRHub.connect()
  }

  private removeSignalRHandlers<T extends Record<string, (...args: any[]) => void>>(
    signalRHub: SignalRHubConnection,
    handlers: T
  ) {
    for (const [key] of Object.keys(handlers)) {
      signalRHub.removeHandler(key)
    }
    signalRHub.disconnect()
  }

  private displaySnackbar(snackbar: ShowSnackbar) {
    this.$store.dispatch('snackbarModule/showSnackbar', snackbar)
  }

  private updateContractorAppointment(deploymentId: string, contractorId: string, appointment: ContractorAppointment) {
    if (!this.isLegacyAutoDeploymentDisabled) {
      return
    }

    const store = useDeploymentStore()
    if (store.autoDeployment.id !== deploymentId) {
      return
    }
    store.updateContractorAppointment({ id: contractorId, appointment: appointment })

    const contractorName = store.autoDeployment.contractors.find((x) => x.contractorId === contractorId)?.contractorName

    const message = contractorName
      ? `AutoDeployment appointment updated for contractor ${contractorName}`
      : 'AutoDeployment appointment updated'
    this.displaySnackbar(new ShowSnackbar(message, 7000, true))
  }

  private updateDeploymentStatus(deploymentId: string, status: string) {
    if (!this.isLegacyAutoDeploymentDisabled) {
      return
    }

    const store = useDeploymentStore()
    if (store.autoDeployment.id !== deploymentId) {
      return
    }
    store.autoDeployment.status = status
    this.displaySnackbar(new ShowSnackbar(`Deployment status updated to ${status}`, 7000, true))
  }

  private updateDeploymentDelay(deploymentId: string, delays: Delay[]) {
    if (!this.isLegacyAutoDeploymentDisabled) {
      return
    }

    const store = useDeploymentStore()
    if (store.autoDeployment.id !== deploymentId) {
      return
    }
    const noDelaysApplied: boolean = !delays.some((x) => x.delayRules.some((x) => x.delayed))
    if (!delays.length || noDelaysApplied) return
    store.updateDeploymentDelay(delays)
  }

  public get isCustomPolicySearchFeatureFlagEnabled(): boolean {
    return !!this.$ld.variation('sfi-735-rsa-policy-search')
  }

  private mounted() {
    this.jobIdChanged()
    // close card preview on cancellation of complete job
    eventBus.$on('closeJobCompleteConfirmation', () => {
      this.clearSelection()
    })
    eventBus.$on('autoDeploymentStarted', (isAutoDeploymentRunning: boolean) => {
      this.getIsAutoDeploymentRunning = isAutoDeploymentRunning
    })
    eventBus.$on('resetEmergencyAddedFromAnswer', () => {
      this.emergencyAddedFromAnswer = []
    })
  }

  @Watch('getSignalRConnectionStatus')
  private updateSignalRGroup() {
    const self = this
    self.liveSync = false
    if (this.jobId) {
      if (!!this.$ld.variation('wip-675-job-notifications')) {
        NotificationsController.AddUserToNotificationsForJob(this.jobId).then((res) => {
          self.liveSync = res
        })
      } else {
        SessionController.AddUserToNotificationsForJob(this.jobId).then((res) => {
          self.liveSync = res
        })
      }
    }
  }

  private get getSignalRConnectionStatus(): ConnectionState {
    return this.signalRHub.status
  }

  private get getIsAutoDeploymentRunning(): boolean {
    if (this.isLegacyAutoDeploymentDisabled) return false

    if (this.isAutoDeploymentRunning) {
      return true
    } else {
      if (this.job) {
        const emergencyWithAutoDeploymentRunning = this.job.emergencies.filter((e) => e.autoDeploymentRunning === true)
        this.isAutoDeploymentRunning = emergencyWithAutoDeploymentRunning.length > 0 ? true : false
      }
      return this.isAutoDeploymentRunning
    }
  }

  private set getIsAutoDeploymentRunning(newValue: boolean) {
    this.isAutoDeploymentRunning = newValue
  }

  private reconnectToGroup() {
    if (this.jobId) {
      if (!!this.$ld.variation('wip-675-job-notifications')) {
        NotificationsController.AddUserToNotificationsForJob(this.jobId)
      } else {
        SessionController.AddUserToNotificationsForJob(this.jobId)
      }
    }
  }

  private async refreshData() {
    if (this.jobId) {
      storeMutations.setJobRefreshRequested(true)
      JobController.GetJobDocuments(this.jobId)
    }
  }

  private get isLegacyAutoDeploymentDisabled(): boolean {
    return !!this.$ld.variation('fnol-401-multiple-contractor-notifications')
  }

  @Watch('jobId')
  private async jobIdChanged() {
    this.$store.dispatch('jobModule/selectJob', this.jobId)

    eventBus.$emit('clearSearchInput', this.jobId, false)
    // if auto deployment of any emergency is running, show overlay
    let isAutoDeploymentRunning = 0
    if (this.job && this.job.emergencies) {
      isAutoDeploymentRunning = this.job.emergencies.filter((x) => x.autoDeploymentRunning).length
    }
    if (isAutoDeploymentRunning > 0) {
      this.getIsAutoDeploymentRunning = true
    } else {
      this.getIsAutoDeploymentRunning = false
    }
    // changed page, update signalR Group
    this.updateSignalRGroup()
    // changed page, check for job
    if (this.jobId && !this.job) {
      // not in vuex store, download
      JobController.GetJobDocuments(this.jobId).then((jobFound) => {
        if (!jobFound) {
          this.$router.push({
            name: 'notFound',
          })
        }
      })
    }
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private cardWithDetailActivated(model: TimeLineCardModel | null) {
    this.previewModel = model
  }

  private clearSelection(): void {
    const timeLineControl = this.$refs.timeLine
    this.previewModel = null
    if (timeLineControl) {
      ;(timeLineControl as any).clearActive()
    }
  }

  private emergencyChipClicked(emergencyId: string): void {
    if (this.job) {
      storeMutations.toggleEmergencyVisiblity(this.job.id, emergencyId)
      this.job.triggerTimeLineRebuild()
    }
  }

  private customerToPayClicked(): void {
    const timeLineControl = this.$refs.timeLine
    if (timeLineControl) {
      ;(timeLineControl as any).addCustomerToPayCard()
    }
  }

  private complaintClicked(): void {
    const timeLineControl = this.$refs.timeLine
    if (timeLineControl) {
      ;(timeLineControl as any).addComplaintCard()
    }
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private async validateEmergency(): Promise<boolean> {
    const subs = this.$refs.addEmergency as AddHEEmergency
    let result: boolean = await subs.$validator.validateAll()
    // validate the date picker of firstNoticed
    let firstNoticedValidation = true
    const dateTimePickerSub = subs.$refs.dateTimePicker as DateTimePicker
    firstNoticedValidation = await dateTimePickerSub.$validator.validateAll()
    // set focus to non validate field
    if (!result || !firstNoticedValidation) {
      Shared.setValidationFocus(result ? (dateTimePickerSub.$el as HTMLElement) : (this.$el as HTMLElement))
      result = result && firstNoticedValidation
    }
    return result
  }

  private openAddEmergency(): void {
    this.claimModel = new AddHEJobWithEmergencies()
    this.addEmergency = new AddEmergency()
    this.addEmergency.policyId = this.job ? this.job.policyScheduleId : 0
    this.addEmergency.jobId = this.job ? this.job.jobId : '0'
    this.policyUrl = this.job ? this.job.policyScheduleImageUrl : ''
    this.showEmergencyDialog = true
  }

  private closeAddHEEmergencyDialog() {
    // Remove validation of addHEEmergency
    const subs = this.$refs.addEmergency as AddHEEmergency
    subs.$validator.errors.items = []
    // Remove validation of first time noticed of addHEEmergency
    const dateTimePickerSub = subs.$refs.dateTimePicker as DateTimePicker
    dateTimePickerSub.$validator.errors.items = []
    this.showEmergencyDialog = false
  }

  private getClaimData(): AddHEJobWithEmergencies {
    if (this.job) {
      this.claimModel.clientTitle = this.job.clientTitle
      this.claimModel.clientForename = this.job.clientForename
      this.claimModel.clientLastName = this.job.clientLastName
      this.claimModel.secondaryClientTitle = this.job.secondaryClientTitle
      this.claimModel.secondaryClientForename = this.job.secondaryClientForename
      this.claimModel.secondaryClientLastName = this.job.secondaryClientLastName
      this.claimModel.addressLine1 = this.job.addressLine1
      this.claimModel.addressLine2 = this.job.addressLine2
      this.claimModel.addressLine3 = this.job.addressLine3
      this.claimModel.addressLine4 = this.job.addressLine4
      this.claimModel.postCode = this.job.postCode
      this.claimModel.email = this.job.email
      this.claimModel.mobilePhone = this.job.mobilePhone
      this.claimModel.contactPreference = this.job.contactPreference
      this.claimModel.policyNumber = this.job.policyNumber
      this.claimModel.customerRef = this.job.customerRef
      this.claimModel.policyScheme = this.job.policyScheme
      this.claimModel.insurer = this.job.insurer
      this.claimModel.insuranceValue = this.job.insuranceValue
      this.claimModel.policyScheduleId = this.job.policyScheduleId
      this.claimModel.nomineeClientTitle = this.job.nomineeClientTitle
      this.claimModel.nomineeClientForename = this.job.nomineeClientForename
      this.claimModel.nomineeClientLastName = this.job.nomineeClientLastName
      this.claimModel.relation = this.job.relation
      this.claimModel.nomineeMobilePhone = this.job.nomineeMobilePhone
      this.claimModel.nomineeEmail = this.job.nomineeEmail
      this.claimModel.nomineeContactPreference = this.job.nomineeContactPreference
      this.claimModel.FNOLCallSid = storeGetters.getCurrentCallSid()
      if (this.addEmergency) {
        this.addEmergency.jobId = ''
        this.claimModel.emergencies = this.claimModel.emergencies || []
        this.claimModel.emergencies.push(this.addEmergency)
      }
      // set the call sid if we're on a call
      this.claimModel.FNOLCallSid = storeGetters.getCurrentCallSid()
    }
    return this.claimModel
  }

  private saveClaim(jobCreationInProcess = false) {
    const self = this
    self.jobCreationInProcess = jobCreationInProcess
    self.isLoading = true
    const claimModel: AddHEJobWithEmergencies = this.getClaimData()
    JobController.SaveClaim(claimModel)
      .then((res: string | null) => {
        // submitted ok, route to it
        if (!res) {
          eventBus.$emit('errorHandler', 'error submitting job, please try again', true)
          self.isLoading = false
          self.jobCreationInProcess = false
          return
        }
        self.showEmergencyDialog = false
        self.isLoading = false
        setTimeout(() => {
          if (jobCreationInProcess) {
            self.jobCreationInProcess = false
            self.$router.push({ name: 'job', params: { jobId: res } })
          } else {
            self.jobCreationInProcess = false
            const routeData = this.$router.resolve({ path: '/job/' + res })
            window.open(routeData.href, '_blank')
          }
        }, 300)

        if (this.isCustomPolicySearchFeatureFlagEnabled) {
          this.$store.dispatch('policyModule/updatePolicyDetails', res)
        }
      })
      .catch((err: any) => {
        self.isLoading = false
        self.jobCreationInProcess = false
        eventBus.$emit('errorHandler', 'error submitting job, please try again', true)
      })
  }

  private AddNewEmergencyToJob(): void {
    const self = this
    self.showNewJobConfirmationOnAddEmergency = false
    self.isLoading = true
    if (self.addEmergency) {
      JobController.AddNewEmergencyToJob(self.addEmergency)
        .then((res: boolean) => {
          if (res) {
            self.isLoading = false
            self.showEmergencyDialog = false
          } else {
            self.isLoading = false
          }
        })
        .catch((err: any) => {
          self.isLoading = false
          eventBus.$emit('errorHandler', 'Error adding new emergency request', true)
        })
    }
  }

  private saveEmergency(): void {
    const self = this
    this.validateEmergency()
      .then((result: boolean) => {
        if (result) {
          // check if logged emergency is linked with thirdPartyContractor
          let emergenciesWithThirdPartyContractors: Emergency[] = []
          if (this.job && this.job.emergencies) {
            emergenciesWithThirdPartyContractors = this.job.emergencies.filter(
              (e) =>
                e.thirdPartyContractor !== '' && e.thirdPartyContractor !== null && e.thirdPartyContractor !== undefined
            )
          }
          if (emergenciesWithThirdPartyContractors.length > 0) {
            // if logged emergency is linked with thirdPartyContractor then create new Job rather than add new emergency.
            this.saveClaim()
          } else {
            if (self.addEmergency) {
              // check if newly added emergency is linked with thirdPartyContractor
              PolicyHolderController.GetThirdPartyContractor(
                self.addEmergency.policyId,
                self.addEmergency.emergencyTypeId
              ).then((res: EmergencyThirdPartyTrade | null) => {
                if (res && res.contractorId) {
                  // if newly added emergency is linked with thirdPartyContractor then create new Job rather than add new emergency.
                  this.saveClaim()
                } else {
                  // if newly added emergency is not linked with thirdPartyContractor then ask to add new emergency to existing job or create new job basis of existing information.
                  this.showNewJobConfirmationOnAddEmergency = true
                }
              })
            }
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private destroyed() {
    this.removeSignalRHandlers(this.deploymentSignalRHub, this.deploymentSignalRHandlers)
  }
}
</script>

<style scoped>
.map {
  padding-bottom: 82%;
  position: relative;
  height: 0;
  margin-bottom: 10px;
  height: 380px;
}
.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page-content {
  display: flex;
  flex: 1 1 0;
  overflow: auto;
}
.page-content-side {
  flex: 3;
}
.page-content-side >>> .vb-dragger {
  right: 8px;
}
.page-content-main {
  flex: 5;
  background: #f5f5f6;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.page-content-side.jobview-card-sidebar {
  flex: 4;
  position: relative;
  z-index: 1;
}
.toolbar__title {
  line-height: 1.3;
}
.map-content-sidebar >>> .vb-dragger {
  right: 0px !important;
}
.text-wrap-chip >>> .v-chip.v-chip--small {
  max-width: 100%;
}
.text-wrap-chip >>> .v-chip.v-chip--small .v-chip__content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  height: auto;
}
@media only screen and (max-width: 1300px) {
  .page-content-side.jobview-card-sidebar {
    flex: 5;
  }
}
.btn-card-filter >>> button.v-btn {
  background-color: #e0e0e0 !important;
}
.btn-card-filter button.active-car-flbtn {
  background-color: #009688 !important;
  color: #fff !important;
}

.chip-btn >>> .v-chip__content {
  cursor: pointer;
}
.system-bar--window {
  height: auto !important;
  min-height: 32px;
}
.system-bar--window .toolbar__title {
  white-space: normal;
  margin-left: 8px;
}
.additional-payment >>> .timeline-cards:first-child .arrow {
  border-color: transparent transparent #4caf50 #4caf50;
}
.additional-payment >>> .timeline-cards:first-child .card-inner-detail h3 {
  background: #4caf50;
  color: #fff;
}
.additional-payment >>> .timeline-cards:first-child .card-inner-detail {
  background-color: #dce8de;
}
.overlay-msg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 6;
}
.auto-deployment >>> .page-content-main {
  z-index: 10;
}
</style>
