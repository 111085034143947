<template>
  <div v-if="jobOffer">
    <v-layout>
      <v-flex xs12 px-2 py-1>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Job:</label>
          <span>{{ jobOffer.contractorAppointedModel.jobId }}</span>
        </div>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Post Code:</label>
          <span>{{ jobOffer.contractorAppointedModel.postcode }}</span>
        </div>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Client Name:</label>
          <span>{{ jobOffer.contractorAppointedModel.customerName }}</span>
        </div>
        <div class="mb-2 d-flex justify-space-between">
          <div>
            <label class="mr-1 font-weight-bold">Emergency:</label>
            <span>{{ jobOffer.emergencyTypeDescription }}</span>
            <span v-if="jobOffer.emergencyDetailDescription">
              {{ jobOffer.emergencyDetailDescription }}
            </span>
          </div>
          <div>
            <label class="mr-1 font-weight-bold">Trade Type:</label>
            <span>{{ jobOffer.tradeName }}</span>
          </div>
        </div>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Address:</label>
          <span>{{ jobOffer.contractorAppointedModel.address }}</span>
        </div>
        <div v-if="jobOffer.engineerSiteSla">
          <label class="mr-1 font-weight-bold">SLA cut off time :</label>
          <span>{{ formatDate(jobOffer.engineerSiteSla) }}</span>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex class="mr-3">
        <DateTimePicker
          ref="etaFrom"
          :date-time.sync="etaFrom"
          :is-static-location="false"
          place-holder-text="ETA From"
          :is-validation-required="true"
          :allowed-minutes-step="true"
        />
      </v-flex>
      <v-flex>
        <DateTimePicker
          ref="etaTo"
          :date-time.sync="etaTo"
          :is-static-location="false"
          place-holder-text="ETA To"
          :is-validation-required="true"
          :allowed-minutes-step="true"
        />
      </v-flex>
    </v-layout>
    <v-layout>
      <h4 v-if="etaErrorMessage" class="warning--text">{{ etaErrorMessage }}</h4>
    </v-layout>
    <v-flex>
      <CustomerAvailabilityDisplay :availability="jobOffer.customerAvailability" />
    </v-flex>
    <v-divider class="mt-2"></v-divider>

    <v-layout class="justify-end">
      <v-flex xs4 class="d-flex justify-end">
        <v-btn class="green white--text" @click="acceptJob"> Accept Job </v-btn>
        <v-btn class="red white--text" @click="showRejectJobConfirmationDialog = true"> Reject Job </v-btn>
      </v-flex>
    </v-layout>

    <v-dialog v-model="showRejectJobConfirmationDialog" width="400" persistent>
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Reject Job</v-toolbar-title>
        </v-toolbar>
        <v-card-text>Are you sure you want to reject this job?</v-card-text>
        <v-card-actions>
          <v-btn class="white--text" color="blue" @click="showRejectJobConfirmationDialog = false">Cancel</v-btn>
          <v-btn class="white--text" color="red" @click="setContractorDecisionToRejectJob">Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <v-progress-circular
    v-else-if="isJobOfferDetailsLoading && !jobOffer"
    class="loading-spinner"
    :width="2"
    :size="50"
    indeterminate
    color="primary"
  ></v-progress-circular>
  <div v-else><h1>Failed to load job details</h1></div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import DeploymentController from '@/api/deployment-controller'
import { AppointmentDetail } from '@/api/models/deployment/contractor/appointment-detail'
import CustomerAvailabilityDisplay from '../CustomerAvailabilityDisplay.vue'
import DateTimePicker from '../DateTimePicker.vue'
import moment, { Moment } from 'moment'
import { TimeSlot } from '@/api/models/deployment/contractor/time-slot-response'
import Shared from '@/common/shared'
import { useDeploymentStore } from '@/pinia/deployment'
import { storeToRefs } from 'pinia'
import { AcceptJobOfferRequest } from '@/api/models/deployment/accept-job-offer-request'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import { Watch } from 'vue-property-decorator'
import Store from '@/store'

@Component({
  components: {
    CustomerAvailabilityDisplay,
    DateTimePicker,
  },
})
export default class JobOffer extends Vue {
  public etaFrom: Moment | null = null
  public etaTo: Moment | null = null
  public etaErrorMessage: string | null = null

  public isJobOfferDetailsLoading = true

  public showRejectJobConfirmationDialog = false

  public async created() {
    const deploymentStore = useDeploymentStore()
    const { jobId, appointmentId } = this.deploymentDetail
    if (!jobId.value || !appointmentId.value) {
      this.isJobOfferDetailsLoading = false
      return
    }
    try {
      await deploymentStore.retrieveJobOffer(jobId.value, appointmentId.value)
    } catch {
      this.$store.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar('Job offer no longer available'))
      this.$emit('offerExpired')
    }
    this.isJobOfferDetailsLoading = false
  }

  public get deploymentDetail() {
    const { jobId, appointmentId } = storeToRefs(useDeploymentStore())
    return {
      jobId,
      appointmentId,
    }
  }

  public get sortedTimeSlots(): TimeSlot[] {
    return (
      this.jobOffer?.customerAvailability.timeSlots.sort((a, b) => moment(a.startTime).diff(moment(b.startTime))) || []
    )
  }

  private get isSlaCheckEnabled(): boolean {
    return !!this.$ld.variation('contrator-portal-sla-fix')
  }

  @Watch('etaFrom')
  @Watch('etaTo')
  public timeChanged() {
    this.validateDates()
  }

  public setContractorDecisionToRejectJob() {
    const deploymentStore = useDeploymentStore()
    deploymentStore.isJobOfferRejected = true
    this.showRejectJobConfirmationDialog = false
  }

  public get jobOffer(): AppointmentDetail | null {
    const { jobOffer } = storeToRefs(useDeploymentStore())
    return jobOffer.value
  }

  public get isTimeWithinSla() {
    return (
      this.jobOffer &&
      this.etaFrom &&
      this.etaTo &&
      this.etaFrom < moment(this.jobOffer.engineerSiteSla) &&
      this.etaTo < moment(this.jobOffer.engineerSiteSla)
    )
  }

  public isTimeWithinCustomerSchedule(timeSlots: TimeSlot[], startTime: Moment, endTime: Moment): boolean {
    if (!timeSlots.length) {
      return false
    }

    const validSlot = timeSlots.find(
      (slot) => startTime.isSameOrAfter(moment(slot.startTime)) && startTime.isBefore(moment(slot.endTime))
    )

    if (!validSlot) {
      return false
    }

    const startSlotIndex = timeSlots.indexOf(validSlot)
    const currentRange = {
      start: moment(validSlot.startTime),
      end: moment(validSlot.endTime),
    }

    // Extend range if we find consecutive slots
    for (let i = startSlotIndex + 1; i < timeSlots.length; i++) {
      const nextSlot = timeSlots[i]
      const nextStart = moment(nextSlot.startTime)

      // Check if slots are consecutive (within 1 hour)
      if (Math.abs(nextStart.diff(currentRange.end, 'hours', true)) > 1) {
        break
      }

      currentRange.end = moment(nextSlot.endTime)
    }

    return endTime.isSameOrBefore(currentRange.end)
  }

  // This should be done using vee-validate, but the current DateTimePicker component is a mess and needs re-written
  private async validateDates(): Promise<boolean> {
    if (!this.jobOffer) {
      this.etaErrorMessage = 'No job appointment data available. Please contact support.'
      return false
    }

    const isEtaFromValid: boolean = await (this.$refs.etaFrom as DateTimePicker).$validator.validateAll()
    const isEtaToValid: boolean = await (this.$refs.etaTo as DateTimePicker).$validator.validateAll()

    if (!isEtaToValid || !isEtaFromValid || !this.etaFrom || !this.etaTo) {
      this.etaErrorMessage = 'Please select an estimated timescale for arrival'
      return false
    }

    if (this.etaFrom > this.etaTo) {
      this.etaErrorMessage = 'Estimated arrival start time must be less than estimated arrival end time'
      return false
    }

    // Is the contractors selected time range is outside of the SLA.
    if (!this.jobOffer.hasCustomerAvailabilityDelay && !this.isTimeWithinSla && this.isSlaCheckEnabled) {
      this.etaErrorMessage = 'Cannot deploy a job outside of the SLA timeframe'
      return false
    } else if (
      (!this.jobOffer.firstUpdatedAt ||
        (this.etaFrom < moment(this.jobOffer.firstUpdatedAt) && this.etaTo > moment(this.jobOffer.engineerSiteSla))) &&
      !this.isSlaCheckEnabled
    ) {
      this.etaErrorMessage = 'Cannot deploy a job outside of the SLA timeframe'
      return false
    }

    if (!this.isTimeWithinCustomerSchedule(this.sortedTimeSlots, this.etaFrom, this.etaTo)) {
      this.etaErrorMessage = 'The times you have selected fall outside the customers availability'
      return false
    }

    this.etaErrorMessage = null
    return true
  }

  public async acceptJob() {
    if (!(await this.validateDates())) return
    if (!this.jobOffer) {
      //do something
      return
    }

    const request: AcceptJobOfferRequest = {
      jobId: this.deploymentDetail.jobId.value!,
      emergencyId: this.jobOffer.contractorAppointedModel.emergencyId,
      legacyAppointmentId: this.jobOffer.contractorAppointedModel.id,
      deploymentId: this.jobOffer.deploymentId,
      appointmentRequestId: this.jobOffer.appointmentRequestId,
      contractorId: this.jobOffer.contractorAppointedModel.contractorId,
      etaTo: this.etaTo!.toDate(),
      etaFrom: this.etaFrom!.toDate(),
    }
    const result = await DeploymentController.AcceptJobOffer(request)
    if (result?.isApproved) {
      useDeploymentStore().isJobOfferAccepted = true
    } else {
      this.$store.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar('Job offer no longer available'))
      this.$emit('offerExpired')
    }
  }

  public formatDate(date: Date) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
